@font-face {
  font-family: "Playfair-Display";
  src: url("./../assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}
body {
  overflow-x: hidden;
}
.home {
  text-align: center;
  margin: 20px auto;
  margin-top: -5%;
  margin-bottom: 90px;
  align-items: center;
  padding-top: 8%;
}

.home h1 {
  text-align: center;
  font-size: 2.5em;
  color: black;
}

h2 {
  font-size: 1.7em;
}

.three-steps {
  width: 90%;
  max-width: 1200px; /* Maximum width of the content, adjust as needed */
}

.three-steps-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack children vertically */
  width: 100%; /* Take full width of the container */
  margin-top: 0%;
  margin-bottom: -0.1%;
}
.whoWeAre {
  color: black;
  text-align: center;
  font-family: Playfair-Display;
}
.whoWeAre-services {
  color: black;
  text-align: center;
  font-family: Playfair-Display;
  display: block;
}
.welcomBanner {
  padding-top: 25px;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.welcom-text {
  font-family: Playfair-Display;
}

h2 {
  font-family: Playfair-Display;
}

.card-body {
  font-size: medium;
  font-family: Montserrat;
}

.slogan {
  font-size: 1.35em;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid orange;
  color: #666661;
  width: 34ch;
  animation: typing 4s steps(24), blink-caret 0.75s step-end infinite;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: -10%;
  padding-bottom: 4%;

  /* display: flex;
  justify-content: center;
  align-items: center; 
  width: 100%;
  padding-top: -10%;
  padding-bottom: 4%;
  background-image: url("../assets/Images/bg.webp");
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  background-attachment: fixed; */
}

.welcom-body {
  text-align: center;
  font-size: 1.3em;
  color: #666;
  font-family: Montserrat;
  line-height: 150%;
  text-align: left;
  max-width: 100%;
  border-left: solid #f8f4ec;
  font-size: larger;
  background: rgba(201, 209, 163, 0.6);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3%;
}

.banner-container {
  min-width: 110%;
  background-color: #c9d1a3;
}

.banner-text {
  text-align: center;
  max-width: 80%; /* Maximum width of 80% */
  margin: 0 auto; /* Center the div */
  color: black;
  padding: 20px;
}
.image-container img {
  width: 160%;
  /* max-width: 100%; */
  height: auto; /* Maintain aspect ratio */
  /* Set a max-height or width if you need to restrict the image size */
  margin-left: 0px;
  margin-top: -27%;
  margin-bottom: -27%;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url("../assets/Images/bg.webp");
  background-size: cover;
  background-position: center;
  z-index: -1;
  height: 100vh;
  margin-bottom: 0%;
}

.home-top {
  position: relative;
  z-index: 1;
  min-height: 100vh;
}

.image-business {
  /* background-image: url("../assets/Images/bg2.png"); */
  /* background-color: #c9d1a3; */
  background: transparent;
  margin-top: 4%;
  padding-top: 2%;
  border-top: solid black 1px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-business img {
  margin-top: 2%;
  margin-bottom: 2%;
  width: 50%;
  height: auto;
  display: inline-flex;
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for scale and filter */
}
.image-business img:hover {
  transform: scale(1.1); /* Slightly increase the size */
  filter: brightness(110%); /* Slightly increase the brightness */
}

@keyframes dropdown {
  from {
    opacity: 0;
    transform: translateY(-80%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.image-business.drop-down {
  animation: dropdown 0.8s ease forwards;
}

.initial-meeting {
  background-color: #dde2c5 !important;
  border: solid 2px #e0d4cc !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin: 1px 0;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
}

.initial-meeting:hover {
  transform: scale(1.05); /* Increase size slightly */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for glow effect */
}

.initial-meeting h2,
.initial-meeting p {
  font-family: "Montserrat";
  color: #333;
  font-size: 1.1em;
}

.initial-meeting h2 {
  font-family: "Montserrat";
  margin-bottom: 10px;
  font-size: 1.3em;
  color: black;
  font-weight: 500;
  margin-top: -7%;
}

.initial-meeting p {
  font-family: "Montserrat";
  font-size: 1.1em;
}

.three-step-title {
  background-color: black;
  color: white;
  padding: 0.7%;
  width: 100%;
  font-family: "Playfair-Display";
  border-top: solid #e0d4cc 5px;
  margin-top: 0%;
}

.fade-in-section {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}
.welcome-and-image {
  margin-top: -5%;
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: center;
  /* justify-content: space-around; */
  /* background: linear-gradient(to bottom, #ffffff, #f6f1eb, #ffffff); */
}

/* The typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 34ch;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}
@media (max-width: 640px) {
  .image-business img {
    width: 70%;
  }
  .home {
    margin-bottom: 0%;
  }
  .welcome-and-image {
    margin-top: 0%;
    display: flex;
    align-items: center; /* Aligns items vertically in the center */
    justify-content: space-around; /* Spreads the items evenly with space around them */
    flex-direction: column;
    border-bottom: solid #e0d4cc;
  }
  .welcomBanner {
    margin-top: 15%;
    padding: 0.8px;
    align-items: center;
    justify-content: center;
  }
  .home h1 {
    font-size: 1.5em;
  }
  .slogan {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .welcom-body,
  .image-container {
    max-width: 100%;
  }
  .three-steps-container {
    margin-top: 0% !important;
  }

  .image-container {
    padding-top: 15%;
    padding-bottom: 5%;
    margin-bottom: -10%;
    background-size: contain; /* Or a specific size in pixels */
    background-position: top center; /* Adjust as needed */
    background-attachment: scroll;
    background-attachment: fixed;
  }
  .welcom-body {
    text-align: center;
    border: hidden;
  }

  .image-business img {
    max-width: 70%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
}
@media (min-width: 1380px) {
  .three-steps-container {
    margin-bottom: 7%;
  }
}
