@font-face {
  font-family: "Playfair-Display";
  src: url("./../assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

.contact {
  text-align: center;
  margin-top: 0%;
  padding-bottom: 20px;
  background-color: #ffffff;
  border-top: solid black 1px;
  /* background-color: #f8f4ec; */
}

.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
}
.contact-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  width: 30%;
}
.keep-in-touch {
  font-family: "Montserrat";
  font-weight: normal;
}
.social-logos {
  display: flex;
  justify-content: center;
  margin-bottom: 2%;
}

.social-logos > * {
  margin: 0 10px;
}

.get-in-touch {
  padding-top: 1%;
  min-width: 80% !important;
}

.contact-item a,
.social-logos a {
  text-decoration: none;
  color: inherit;
}

.contact-item a:hover,
.social-logos a:hover {
  text-decoration: none;
  color: inherit;
}
.contact-title {
  background-color: #e0d4cc;
  line-height: 350%;
  font-family: "Playfair-Display";
  border-bottom: solid black 1px;
}

h2 {
  font-size: 1.7em;
}
.inputRow {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
  width: 50%;
}

.classes.inputField {
  display: inline-block;
  margin-right: 10px; /* Spacing between fields */
}
.classes.inputField {
  width: 100%; /* Default width for larger screens */
  /* Other styles... */
}
@media (max-width: 640px) {
  .get-in-touch {
    padding-top: 7%;
  }
  .contact-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .social-logos {
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
    padding-top: 40px;
  }
  .contact {
    padding-bottom: 50px;
    margin-top: 0px;
  }
  .inputRow {
    display: flex;
    justify-content: space-between; /* Adjust as needed */
    width: 95%;
  }
  .classes.inputField {
    background-color: red;
    width: 95% !important;
  }
}
@media (min-width: 1380px) {
  .contact {
    margin-top: 0px;
  }
}
