@font-face {
  font-family: "Playfair-Display";
  src: url("./../assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}
.about {
  background-color: black;
}
.about {
  text-align: center;
  padding-top: 10%;
}
.about-founder {
  font-size: medium;
  font-family: "Montserrat";
}

.about h1 {
  font-size: 2em;
  color: white;
  font-family: "Playfair-Display";
}

.about p {
  font-size: 1.2em;
  color: black;
  font-family: "Montserrat";
}

/* About.css
.founder-container {
  margin-top: 20px;
  padding-bottom: 20px; /* Add some space below each card 
} */

/* About.css */
.founder-card {
  width: 89%; /* Adjust this value to your preference */
  margin: auto; /* This will center the card in the grid item */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 6px #00000029;
  margin-bottom: 20px;
  padding: 20px;
  background: linear-gradient(to bottom, black, #dde2c5 50%);
  justify-content: space-between;
}

.founder-image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
  filter: brightness(110%);
}

.about-founder {
  text-align: center;
}

.founder-container {
  display: flex;
}

.founder-card {
  display: flex;
  flex-direction: column;
}
.space {
  display: block;
  height: 29px;
}
/* For tablets and smaller devices */
@media (max-width: 768px) {
  .founder-card {
    width: 75%; /* Bigger width for smaller screens */
  }
  .about {
    margin-top: 40%;
  }
}

/* For mobile phones */
@media (max-width: 768px) {
  .founder-card {
    width: 75%; /* Adjust as needed */
  }
  .about {
    margin-top: 20%; /* Adjust top margin for tablets */
  }
}

/* Mobile Phone View */
@media (max-width: 640px) {
  .founder-card {
    width: 90%; /* Larger width for mobile screens */
    padding: 10px; /* Further reduced padding for smaller screens */
  }
  .about {
    margin-top: 11%; /* Corrected margin-top */
    padding-top: 14%; /* Adjusted padding-top */
  }
}
@media (min-width: 1380px) {
  .space {
    display: inline-block;
    height: 0px;
  }
}
