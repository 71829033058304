@font-face {
  font-family: "Playfair-Display";
  src: url("./../assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

.services {
  text-align: center;
  margin-top: 0px !important;
  margin-left: 30px auto;
  margin-right: 30px auto;
  padding-bottom: 10%;
  margin-bottom: 0px;
  align-items: center;
  font-family: "Montserrat";
  background-color: black;
  padding-top: 6%;
}

h3 {
  font-family: "Playfair-Display";
  margin-top: 0%;
  margin-bottom: 3%;
}

.business-title {
  padding-top: 4%;
  color: white;
}

.services-container {
  max-width: 80%;
  margin: auto; /* Centers the container in the parent element */
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centers children horizontally */
  gap: 16px; /* Adds space between the cards */
  align-items: stretch;
}

.services-container .MuiCard-root {
  margin: 10px;
  max-width: 450px;
}
.service-card-list {
  flex: 1 1 calc(50% - 16px); /* Adjust the subtracted value to account for the gap */
  border: solid #e0d4cc 1.6px;
  position: relative;
  background-color: #dde2c5;
  overflow: hidden;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between;

  background-color: inherit;
  transform-origin: top;
}

.service-card-list li {
  list-style-type: none;
  position: relative;
  padding-left: 20px;
}

.service-card-list li:before {
  content: "•";
  position: absolute;
  left: 0;
  font-size: 1em; /* Adjust this value as needed */
  line-height: 1;
  color: gray;
}

.service-title {
  background-color: #e0d4cc;
  line-height: 350%;
  font-family: "Playfair-Display";
  border-bottom: solid black 1px;
}
.service-card {
  display: flex;
  margin: 10px;
}

.service-list {
  list-style-type: square;
  padding: 0;
  margin: 0;
  text-align: left;
}
h2 {
  font-size: 1.7em;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ff0000; /* Example color */
  transform-origin: top; /* Ensure the animation grows from the top */
}

@media (max-width: 640px) {
  .services-container {
    max-width: 90%;
  }
  .services {
    margin-top: -15% !important;
    padding-top: 35%;
    margin-bottom: -10%;
  }
}
