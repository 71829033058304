@font-face {
  font-family: "custom-cursive";
  src: url("./../assets/fonts/famulred/Famulred.otf");
}

@font-face {
  font-family: "Playfair-Display";
  src: url("./../assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}

.coming-soon-button {
  border: solid gray 1px;
  color: black;
}

.coming-soon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 100px;
  background-color: #f6f1eb;
}

.coming-soon-logo {
  max-width: 300px; /* Adjust the size of your logo */
  margin-bottom: 5px;
}

.coming-soon-title {
  font-size: 4em;
  margin-bottom: 0px;
  font-weight: lighter;
}

.coming-soon-text {
  font-family: "custom-cursive";
  font-size: 100px;
  font-weight: lighter;
  margin-top: 15%;
}

.coming-soon-form {
  margin-top: 10px;
  margin-bottom: 10px !important;
}

.access-denied {
  color: red;
  margin-top: 10px;
}

/* Style for the input and button if needed */
.coming-soon-input {
  margin-top: 30px;
  margin-right: 10px;
  padding: 10px;
  font-size: 1em;
}

.coming-soon-button {
  padding: 10px 20px;
  font-size: 1em;
}

@media (max-width: 640px) {
  .coming-soon-text {
    margin-top: 55%;
  }
}
