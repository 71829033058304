.footer {
  background-color: #000;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  bottom: 0;
  width: 100%;
  margin-top: 0%;
}

.footer .contact-info,
.footer .copyright {
  text-align: center;
  margin-bottom: 10px;
}

.footer p {
  margin: 0;
}
