@font-face {
  font-family: "Playfair-Display";
  src: url("./../assets/fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("./../assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: solid #ffffff 1px;
  background-color: black;
  position: fixed; /* Make navbar fixed */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Ensure it spans the full width */
  z-index: 1000; /* Ensure it stays on top of other elements */
  margin-bottom: 0%;
  font-family: "Montserrat";
}

button {
  border: hidden;
  font-family: "Montserrat";
  color: white;
  background: none;
}

button:hover {
  color: #888;
}

.navbar-logo {
  height: 150px;
  margin-bottom: -40px;
  margin-top: -40px;
  margin-left: -50px;
  padding-left: 10%;
}

.navbar-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none; /* Initially hide the navbar links */
  justify-content: center;
  flex-grow: 1;
}

.navbar-nav li {
  margin: 0 15px;
  color: white;
}

.navbar-nav li a {
  color: white;
  text-decoration: none;
  transition: color 0.1s ease, transform 0.1s ease;
  position: relative;
  overflow: hidden;
  padding: 10px 0;
  list-style: none;
}

.navbar-nav li a::before,
.navbar-nav li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background: #000;
  left: 0;
  transition: all 0.1s ease;
}

.navbar-nav li a:hover::before {
  top: 0;
}

.navbar-nav li a:hover::after {
  bottom: 0;
}

.navbar-nav li a:hover {
  color: #888;
  transform: scale(1.1);
}

/* Hamburger Menu for Mobile View */
.hamburger {
  display: none; /* Hide by default */
  color: white; /* White color for better visibility */
  font-size: 30px; /* Larger size for easier interaction */
  cursor: pointer; /* Indicate it's clickable */
}

/* Button and Link Style */
.navbar-nav li,
.mobile-menu a {
  text-decoration: none;
  color: #333; /* Text color */
  padding: 8px 0px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  align-content: left;
  text-align: left;
}

.navbar-nav li:hover,
.mobile-menu a:hover {
  /* background-color: #007bff;  */
  color: white;
}
/* Navbar.css */
.mobile-menu .MuiDrawer-paper {
  background-color: black;
  color: white;
}

/* Additional styling for button to make it look like a link */
.navbar-nav button {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit; /* Ensures the button font matches other elements */
  outline: inherit; /* Keeps consistency in focus outline */
}

/* Common Style for Mobile Menu Links and Buttons */
.mobile-menu a,
.mobile-menu button {
  text-decoration: none;
  color: white; /* Adjust the color as needed */
  padding: 0px; /* Adjust padding as needed */
  display: block; /* Make sure both occupy the full width */
  width: 100%; /* Ensure full width for touch targets */
  text-align: center; /* Center-align the text */
  background: transparent !; /* Transparent background */
  border: none; /* No border for buttons */
  outline: none; /* No outline for buttons */
  font-family: "Montserrat", sans-serif; /* Ensure consistent font */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  align-items: left;
  text-align: left;
  align-content: left;
}

.mobile-menu a:hover,
.mobile-menu button:hover {
  background-color: #dde2c5; /* Change hover background color as needed */
  color: #fff; /* Change hover text color as needed */
}

/* Additional styling for buttons to match links */
.mobile-menu button {
  cursor: pointer;
}

/* For mobile view */
@media (max-width: 599px) {
  .hamburger {
    display: block !important;
    color: white !important;
  }
}

/* For desktop view */
@media (min-width: 600px) {
  .navbar-nav {
    display: flex;
  }
}
